import {ProFormText} from "@ant-design/pro-components";
import {LockOutlined, UserOutlined} from "@ant-design/icons";
import React from "react";

export const FormSignInEmail = () => {
    return (
        <>
            <ProFormText
                name="email"
                fieldProps={{
                    size: 'large',
                    prefix: <UserOutlined className={'prefixIcon'}/>,
                }}
                rules={[{required: true, message: 'O e-mail é obrigatório!'}]}
                placeholder={'Email'}
            />
            <ProFormText.Password
                name="password"
                rules={[{required: true, message: 'A senha é obrigatória!'}]}
                fieldProps={{
                    size: 'large',
                    prefix: <LockOutlined className={'prefixIcon'}/>,
                }}
                placeholder={'Senha'}
            />

        </>
    )
}