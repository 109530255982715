import React, { useEffect, useState } from "react";
import { TableNotification } from "../../containers/tables/notification-table";
import { FormNotification } from "../../containers/forms/form-notification";
import { Button, Modal } from "antd";
import { api } from "../../services/api";

export const NotificationIndexPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isGlobalNotification, setIsGlobalNotification] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notification, setNotification] = useState({
        title: "",
        description: "",
        user_id: null,
        isGlobalNotification: false
    });
    const setInitialState = () => {
        setNotification({
            title: "",
            description: "",
            user_id: null,
            isGlobalNotification: false
        });
    }
    const showModal = () => {
        setIsModalOpen(true);
    }
    const handleCancel = () => {
        setIsModalOpen(false);
    }
    const handleOk = () => {
        setIsModalOpen(false);
        api.post('/notifications',  {
            title: notification.title,
            description: notification.description,
            user_id: notification.user_id,
            isGlobalNotification: isGlobalNotification
        }).then((response) => {
            console.log(response);
            fetchNotifications();
            setInitialState();
        }).catch((error) => {
            console.log(error);
        })
    }
    const fetchNotifications = () => {
        api.get('/notifications/admin').then((response) => {
            setNotifications(response.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    useEffect(() => {
        fetchNotifications()
    }, []);
    return (
        <div>
            <h1>Notificações no Dreambook</h1>
            <Button type="primary" onClick={showModal}>
                Criar nova notificação
            </Button>
            <Modal title="Nova notificação" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <FormNotification notification={notification} setNotification={setNotification} isGlobalNotification={isGlobalNotification} setIsGlobalNotification={setIsGlobalNotification}/>
            </Modal>
            <TableNotification dataSource={notifications}/>
        </div>
    );
}