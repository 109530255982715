import React, { useEffect, useState } from "react";
import { Button, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { TableTeams } from "../../../containers/tables/table-team";
import { FormTeam } from "../../../containers/forms/form-team/form-team";

export const TeamIndexPage = () => {
    const [teams, setTeams] = useState([{
        name: "Teste",
        email: "Email@email.com",
        role: "Admin",
    }]);


    return (
        <div>
            <h1>Equipe do Dreambook</h1>
            <FormTeam/>
            <TableTeams dataSource={teams} />
        </div>
    );
}