import { useContext, useMemo } from "react";
import {
    routesMaster,
} from "../main/routes-role";
import { MeContext } from "../contexts/me-context/me-context";

export const useRouteRole = () => {
    const { role } = useContext(MeContext);

    const routes = useMemo(() => {
        return routesMaster();

    }, [role]);

    return { routes };
};
