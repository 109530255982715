import React from "react";
import { Table } from "antd";

export const TableDreams = ({ dataSource }) => {
    return (
        <Table
            dataSource={dataSource}
            columns={[
                {
                    title: "Titulo",
                    dataIndex: "title",
                    key: "title"
                },
                {
                    title: "Ultimo registro",
                    dataIndex: "start_at",
                    key: "start_at"
                },
                {
                    title: "Usuário",
                    dataIndex: "user_id",
                    key: "user_id"
                }
            ]} />
    );
};