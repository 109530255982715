import React from 'react'
import { ProLayout, type ProLayoutProps } from '@ant-design/pro-components'

export interface LayoutProps extends ProLayoutProps {
    logo: React.ReactNode
    children?: React.ReactNode
    LinkComponent?: any
    locationRouter: any
    routes: Array<{ path?: string, icon: any | string, name: string }>
}

export const LayoutComponent: React.FC<LayoutProps> = ({ LinkComponent, locationRouter, routes, children, logo, ...rest }) => {
    return (
        <div
            style={{
                height: '100vh'
            }}
        >
            <ProLayout
                title={''}
                logo={logo}
                location={locationRouter}
                route={{
                    routes
                }}
                menuItemRender={(item: any, defaultDom: any) => {
                    if (LinkComponent) {
                        return <LinkComponent to={item.path}> {defaultDom} </LinkComponent>
                    }
                    return defaultDom
                }}
                fixSiderbar={true}
                fixedHeader={true}
                {...rest}
            >
                {children}
            </ProLayout>
        </div>
    )
}