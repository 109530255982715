import React, { useEffect, useState } from "react";
import { TableTicket } from "../../containers/tables/table-ticket";
import { api } from "../../services/api";
import { Button, Input, Select, Table } from "antd";
import {Status, Title} from "../../containers/forms/enum-tickets";

export const TicketIndexPage = () => {
    const [ticket, setTicket] = useState([{
        id: '',
        title: '',
        content: '',
        status: '',
        user_id: '1',
        open_at: '',
        close_at: ''
    }])
    const [searchTerm, setSearchTerm] = useState(' ')
    const [selectedFilter, setSelectedFilter] = useState('all')
    const options = [
        { value: 'all', label: 'Todos' },
        { value: 'id', label: 'ID' },
        { value: 'title', label: 'Assunto'},
        { value: 'status', label: 'Status' },
        { value: 'user', label: 'Usuário' },
    ];
    const updateCloseAt = (item) => {
        if (item.status === Status.Finished) {
            item.close_at = new Date();
        }
    };
    useEffect(() => {
        if (ticket) {
            ticket.forEach(updateCloseAt);
        }
    }, [ticket]);
    useEffect(() => {
        api.get("/admin/tickets").then((response) => {
            setTicket(response.data)
            console.log(response.data)
        })
    }, []);
    const handleSearch = (term) => {
        const filteredData = ticket.filter((ticket) => {

            return (
                ticket.title.toLowerCase().includes(term.toLowerCase()) ||
                ticket.content.toLowerCase().includes(term.toLowerCase()) ||
                ticket.user_id.toLowerCase().includes(term.toLowerCase())
            );
        });
        setTicket(filteredData);
    };
    return (
        <div>
            <h1>Tickets dos Usuários</h1>
            {/* Search elements */}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", fontWeight: "bold" }}>
                <div>
                    <Input
                        style={{ width: "250px" }}
                        placeholder="Digite seu termo de pesquisa"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Select
                        style={{ width: 100 }}
                        value={selectedFilter}
                        onChange={(value) => setSelectedFilter(value)}
                        options={options}
                    />
                    <Button type="primary">Pesquisar</Button>
                </div><TableTicket dataSource={ticket}

            />
            </div>
        </div>
    );
};



