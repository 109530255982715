import React, { useEffect, useRef, useState } from "react";
import { Avatar, Button, Input, List, Layout, Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { AiOutlineAliwangwang } from "react-icons/ai";

const { Header, Sider, Content } = Layout;

const ChatIndexPage = () => {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [conversations, setConversations] = useState([]);
    const [currentConversationId, setCurrentConversationId] = useState(null);
    const messagesEndRef = useRef(null);


    const handleSendMessage = () => {
        if (userInput.trim() === '') return;
        const newMessage = {
            user: userInput,
            bot: 'Bem-vindo, Qual sua dúvida?'
        };
        const updatedMessages = [...messages, newMessage];
        setMessages(updatedMessages);
        setUserInput('');

        if (currentConversationId !== null) {
            setConversations(prevConversations =>
                prevConversations.map(conv => {
                    if (conv.id === currentConversationId) {
                        // Atualiza o assunto se for a primeira mensagem
                        const updatedSubject = conv.messages.length === 0 ? newMessage.user : conv.subject;
                        return { ...conv, messages: updatedMessages, subject: updatedSubject };
                    }
                    return conv;
                })
            );
        }
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    const handleNewConversation = () => {
        const newConversation = {
            id: conversations.length,
            subject: '', // O assunto será definido com base na primeira mensagem
            messages: []
        };
        setConversations([...conversations, newConversation]);
        setMessages([]);
        setCurrentConversationId(newConversation.id);
    };

    const handleSelectConversation = (id) => {
        const selectedConversation = conversations.find(conv => conv.id === id);
        if (selectedConversation) {
            setMessages(selectedConversation.messages);
            setCurrentConversationId(id);
        }
    };

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (
        <Layout style={{ height: '100vh' }}>
            <Sider width={300} className="site-layout-background">
                <Menu
                    mode="inline"
                    style={{ height: '100%', borderRight: 0 }}
                >
                    {conversations.map((conv) => (
                        <Menu.Item key={conv.id} onClick={() => handleSelectConversation(conv.id)}>
                            {conv.subject || `Conversa ${conv.id + 1}`} {/* Exibe o assunto ou um nome padrão */}
                        </Menu.Item>
                    ))}
                </Menu>
            </Sider>
            <Layout>
                <Header className="site-layout-sub-header-background" style={{ padding: 0 }} />
                <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                    <div style={{ padding: 24, background: '#fff', textAlign: 'left' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Centraliza os botões
                            padding: '20px'
                        }}>
                            <h1>Chat</h1>
                            <div style={{
                                width: '100%',
                                maxWidth: '600px',
                                height: '400px',
                                border: '1px solid #ccc',
                                padding: '10px',
                                overflowY: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                <List
                                    dataSource={messages}
                                    renderItem={item => (
                                        <>
                                            <List.Item style={{ justifyContent: 'flex-end' }}>
                                                <List.Item.Meta
                                                    avatar={<Avatar icon={<UserOutlined />} />}
                                                    title="Você"
                                                    description={item.user}
                                                />
                                            </List.Item>
                                            <List.Item style={{ justifyContent: 'flex-start' }}>
                                                <List.Item.Meta
                                                    avatar={<Avatar icon={ <AiOutlineAliwangwang />} />}
                                                    title="ChatGPT"
                                                    description={item.bot}
                                                />
                                            </List.Item>
                                        </>
                                    )}
                                />
                                <div ref={messagesEndRef} />
                            </div>
                            <Input
                                value={userInput}
                                onChange={e => setUserInput(e.target.value)}
                                onKeyPress={handleKeyPress}
                                placeholder="Digite sua mensagem"
                                style={{
                                    marginTop: '10px',
                                    width: '100%',
                                    maxWidth: '600px'
                                }}
                            />
                            <div    style={{ flexDirection: "row" }}>
                            <Button type="primary" onClick={handleSendMessage} style={{ marginTop: '10px', maxWidth: '600px', marginRight: 50 }}>
                                Enviar
                            </Button>
                            <Button type="default" onClick={handleNewConversation} style={{ marginTop: '10px', maxWidth: '600px'}}>
                                Nova Conversa
                            </Button>
                            </div>
                        </div>
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
};

export default ChatIndexPage;