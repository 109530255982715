import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { MainLayout } from "./main-layout";
import { MeContext } from "../contexts/me-context/me-context";
import "moment/locale/pt-br";

function App() {
    const [me, setMe] = useState(null);
    const [role, setRole] = useState(null);
    const [filter, setFilter] = useState({
        masterIndex: {},
    });
    useEffect(() => {
        const userAux = localStorage.getItem("user");
        const activeRoleAux = localStorage.getItem("active_role");
        setMe(userAux ? JSON.parse(userAux) : JSON.stringify({ id: null }));
        setRole(
            activeRoleAux
                ? JSON.parse(activeRoleAux)
                : JSON.stringify({ id: null }),
        );
    }, []);
    return (
            <MeContext.Provider value={{ me, setMe, role, setRole }}>
                    <BrowserRouter>
                        <MainLayout me={me} role={role} />
                    </BrowserRouter>
            </MeContext.Provider>
    );
}

export default App;
