import React from "react";
import { Button, Popconfirm, Space, Table, Tag } from "antd";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import {Status, Title} from "../../containers/forms/enum-tickets";



export const TableTicket = ({ dataSource }) => {
    return (
        <Table
            dataSource={dataSource}
            columns={[


                {
                    title: "ID",
                    dataIndex: "id",
                    key: "id",
                    width: 30,


                },
                {
                    title: "Assunto",
                    dataIndex: "title",
                    key: "title",
                    width: 30,
                    render: (obs) => {
                        const title = obs as Title;
                        const color =
                            title === Title.Suggestion
                                ? "green"
                                : title === Title.ReportProblem
                                    ? "Red"
                                    : "blue";
                        return (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Tag color={color}>{title}</Tag>
                            </div>
                        );

                    }
                },
                {
                    title: "Descrição",
                    dataIndex: "content",
                    key: "content",
                    width: 800,

                },
                {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                    render: (text) => {
                        const status = text as Status;
                        const color =
                            status === Status.Open
                                ? "blue"
                                : status === Status.InProgress
                                    ? "orange"
                                    : "green";
                        return (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Tag color={color}>{status}</Tag>
                            </div>
                        );


                    }
                },
                {
                    title: "Usuário",
                    dataIndex: "user_id",
                    key: "user_id",
                    width: 30,
                },
                {
                    title: "Data de Criação",
                    dataIndex: "openAt",
                    key: "openAt",
                    width: 150,
                    render: (openAt) => (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {moment(openAt).format('DD/MM/YYYY')}
                        </div>
                    )
                },
                {
                    title: "Data de Conclusão",
                    dataIndex: "close_at",
                    key: "close_at",
                    width: 200

                },
                {
                    title: 'Ação',
                    key: 'action',
                    render: (_, record) => (
                        <Space size="middle">
                            <Button type="primary">
                                Detalhes
                            </Button>
                            <Button type="primary">
                                Responder
                            </Button>
                            <Popconfirm
                                title="Tem certeza que deseja deletar?"
                            >
                                <Button type="primary">
                                    <DeleteOutlined />
                                </Button>

                            </Popconfirm>
                        </Space>
                    ),
                },
            ]}/>
    );
};

