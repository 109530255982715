import {
    Link,
    Route,
    Routes,
    useLocation,
    useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { SignInFactory } from "../pages/signin/signin-factory";
import { configProject } from "../config-project";
import { FooterLayout } from "./footer-layout";
import { useRouteRole } from "../hooks/use-route-role";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { Image, Spin } from "antd";
import { ResetPassword } from "../pages/reset-password/reset-password";
import { ForgotPassword } from "../pages/forgot-password/forgot-password";
import { LayoutComponent } from "./layout-component";

export const MainLayout = ({ me, role }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const style =
        width < 500
            ? { marginLeft: -16, marginRight: -16, marginBottom: 100 }
            : {};
    const { routes } = useRouteRole();
    const [isMounted, setIsMounted] = React.useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setIsMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            if (
                !me?.id &&
                location.pathname !== "/signin" &&
                location.pathname !== "/reset-password"
            ) {
                navigate("/signin");
            }
            if (me?.id && location.pathname === "/signin") {
                navigate("/");
            }
        }
        // eslint-disable-next-line
    }, [me, isMounted]);

    if (!me?.id) {
        return (
            <Routes>
                <Route path={"/signin"} element={<SignInFactory />} />
                <Route
                    path={"/signup"}
                    element={<Link to={"/signin"}>signup</Link>}
                />
                <Route path={"/forgot-password"} element={<ForgotPassword />} />
                <Route
                    path={"/reset-password"}
                    element={<ResetPassword reload={false} />}
                />
            </Routes>
        );
    }

    return (
        <Spin spinning={loading}>
            <LayoutComponent
                menuExtraRender={() => <div>Profile Header</div>}
                menuHeaderRender={(logo, title, configuration) => {
                    const collapsed =
                        configuration?.collapsed === undefined
                            ? true
                            : configuration?.collapsed;
                    if (
                        configuration?.collapsed === undefined &&
                        configuration?.isMobile === undefined
                    ) {
                        return (
                            <Image
                                style={{ marginBottom: 24 }}
                                height={30}
                                src={require("../assets/dream-book-logo.png")} // Mobile
                                preview={false}
                            />
                        );
                    }
                    if (collapsed && !configuration?.isMobile) {
                        return (
                            <Image
                                style={{ maxHeight: 30 }}
                                src={require("../assets/dream-book-logo.png")} // Collapsed
                                // src={require("../assets/logo-white-vertical.png")} // Collapsed
                                preview={false}
                            />
                        );
                    }
                    return collapsed ? (
                        logo
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                justifyContent: "start",
                                alignItems: "start",
                                display: "flex",
                                marginTop: configuration?.isMobile ? 8 : -8,
                                marginLeft: configuration?.isMobile ? 12 : 0,
                                marginBottom: configuration?.isMobile ? 12 : -8,
                            }}
                        >
                            <Image
                                style={{ maxHeight: 36 }}
                                src={require("../assets/dream-book-logo.png")} // Open sidebar
                                preview={false}
                            />
                        </div>
                    );
                }}
                token={configToken}
                locationRouter={location}
                logo={require("../assets/dream-book-logo.png")}
                title={"Dreambook"}
                LinkComponent={Link}
                routes={routes.location}
                siderMenuType={role?.role === "MASTER" ? "group" : undefined}
                menuFooterRender={FooterLayout}
                style={{ height: "100vh" }}
            >
                <div style={style}>{routes.routes}</div>
            </LayoutComponent>
        </Spin>
    );
};

const configToken = {
    sider: {
        colorMenuBackground: configProject.colorMenuBackground,
            colorTextMenu: configProject.colorTextMenu,
            colorTextMenuSelected:
        configProject.colorTextMenuSelected,
            colorTextMenuItemHover:
        configProject.colorTextMenuSelected,

            colorTextMenuTitle: configProject.colorMenuBackground,
            colorBgCollapsedButton:
        configProject.colorMenuBackground,
            colorBgMenuItemCollapsedElevated:
        configProject.colorMenuBackground,
    },
    colorPrimary: configProject.colorPrimary,
        header: { colorBgHeader: configProject.colorBgHeader },
}
