import React, { useContext, useState } from "react";
import { SignInPage } from "./signin-page";
import { MeContext } from "../../contexts/me-context/me-context";
import { api } from "../../services/api";
import { notification } from "antd";

const failSearchRole = () => {
    notification.error({ message: "Erro ao buscar roles, contate o suporte" });
    localStorage.clear();
};

export const SignInFactory = () => {
  const { setMe, setRole } = useContext(MeContext);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const signInWithEmail = ({ email = "", password = "" }) => {
    setIsLoading(true);
    api
      .post("/sign-in", { email: email.trim(), password: password.trim()})
      .then(({ data: dataUser }) => {
        console.log(dataUser)
        localStorage.setItem("user", JSON.stringify(dataUser));
        setMe(dataUser);
      })
      .catch((e) => {
        console.warn(e);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <SignInPage
      loading={isLoading}
      onSignInEmail={signInWithEmail}
    />
  );
};
