import React from "react";
import { Table } from "antd";

export const TableNotification = ({ dataSource }) => {
    return (
        <Table
            dataSource={dataSource}
            columns={[
                {
                    title: "Titulo",
                    dataIndex: "title",
                    key: "title"
                },
                {
                    title: "Conteúdo",
                    dataIndex: "description",
                    key: "description"
                },
                {
                    title: "ID do Usuário",
                    dataIndex: "users",
                    key: "users",
                    render: (users) => users?.length > 1 ? "Todos os usuários" : users?.[0]?.user_id
                }
            ]} />
    );
};