import { Button, Divider, Form, Space, Tooltip } from "antd";
import React from "react";
import { FaCogs } from "react-icons/fa";

export const SigninFastLogin = () => {
    const form = Form.useFormInstance();
    return (
        <Space
            size={0}
            split={<Divider type={"vertical"} />}
            style={{
                marginBottom: 24,
                width: "100%",
                justifyContent: "center",
            }}
        >
            <Tooltip title={"Master"}>
                <Button
                    type={"link"}
                    icon={<FaCogs />}
                    onClick={() => {
                        form.setFieldsValue({
                            email: "master@rockapps.com.br",
                            password: "rockapps",
                        });
                    }}
                />
            </Tooltip>
        </Space>
    );
};
