import React from "react";
import { Card, Checkbox, CheckboxProps, Input, Row } from "antd";

export const FormNotification = ({notification, setNotification, isGlobalNotification, setIsGlobalNotification}: any) => {
    const onChange: CheckboxProps['onChange'] = (text) => {
        setIsGlobalNotification(text.target.checked);
    };

    return (
        <Card>
            <h2>Titulo da notificação</h2>
            <Input
                type='text'
                name='title'
                placeholder='Digite o título da notificação'
                value={notification.title}
                onChange={(text) => setNotification({...notification, title: text.target.value})}
            />
            <h2>Conteúdo</h2>
            <Input
                type='text'
                name='content'
                style={{ height: '100px'}}
                placeholder='Digite o conteúdo da notificação'
                value={notification.description}
                onChange={(text) => setNotification({...notification, description: text.target.value})}
            />
            <h2>ID do Usuário</h2>
            <Row justify={"space-between"}>
                <Input
                    type='number'
                    name='content'
                    style={{ width: '33%'}}
                    value={notification.user_id}
                    onChange={(text) => setNotification({...notification, user_id: text.target.value})}
                />
                <Checkbox onChange={onChange}>Todos os usuãrios</Checkbox>
            </Row>

        </Card>
    );
}