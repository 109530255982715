import { Button, Input, Select } from "antd";
import React, { useState } from "react";
import { number } from "prop-types";
import { api } from "../../../services/api";

export const FormTeam = ({ role }: any) => {
    const [teams, setTeams] = useState({
        name: "",
        email: "",
        password: "",
        role: role,
    });

    const handleChange = (event) => {
        setTeams({ ...teams, [event.target.name]: event.target.value });
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                width: "50%",
                margin: "auto",
                marginTop: "20px",
            }}
        >
            <div style={{ display: "flow", justifyContent: "flex-end" }}>
                <h2>Nome</h2>
                <Input
                    type='text'
                    name='name'
                    value={teams.name}
                    onChange={handleChange}
                />
                <h2>Email</h2>
                <Input
                    type='email'
                    name='email'
                    value={teams.email}
                    onChange={handleChange}
                />
                <h2>Senha</h2>
                <Input
                    type='password'
                    name='password'
                    value={teams.password}
                    onChange={handleChange}
                />
            </div>
            <Button
                style={{ width: "100px", margin: "auto", marginTop: "20px" }}
                onClick={() => {
                api.post("user/admin", teams).then((response) => {
                    console.log(response)
                })
                console.log(teams)
            }}>Create user</Button>
        </div>
    );
};