import React from "react";
import { Route, Routes } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import { UserIndexPage } from "../pages/user/user-index/user-index-page";
import { GiNightSleep } from "react-icons/gi";
import { UserCreateIndexPage } from "../pages/user/user-create/user-create-index-page";
import { RiAdminFill, RiTeamFill } from "react-icons/ri";
import { DreamIndexPage } from "../pages/dream/dream-index/dream-index-page";
import { TeamIndexPage } from "../pages/team/team-index/team-index-page";
import { AdminIndexPage } from "../pages/admin/admin-index-page";
import { IoIosNotifications } from "react-icons/io";
import { NotificationIndexPage } from "../pages/notification/notification-index-page";
import { IoTicket } from "react-icons/io5";
import { BsChatSquareText } from "react-icons/bs";
import ChatIndexPage from "../pages/chat/chat-index-page";
import { TicketIndexPage } from "../pages/ticket/ticket-index-page";



export const routesMaster = () => {
  return {
    location: [
        {
          path: "/users",
          name: "Usuários",
          id: "users",
          icon: <FaUser />  ,
        },
        {
          path: "/admin",
          name: "Administrador",
          id: "admin",
          icon: <RiAdminFill />  ,
        },
        {
          path: "/dreams",
          name: "Sonhos",
          id: "dreams",
          icon: <GiNightSleep/>,
        },
        {
          path: "/team",
          name: "Equipe",
          id: "team",
          icon: <RiTeamFill/>,
        },
        {
          path: "/notification",
          name: "Notificações",
          id: "notification",
          icon: <IoIosNotifications />,
        },
        {
          path: "/ticket",
          name: "Tickets",
          id: "ticket",
          icon: <IoTicket />,
        },
        {
            path: "/chat",
            name: "Chat",
            id: "Chat",
            icon: <BsChatSquareText />,

        }
      // {
      //   path: "/",
      //   name: "Operacional",
      //   id: "master",
      //   children: [
      //     modules.master.location,
      //     ...(process.env.REACT_APP_ENV !== "production" &&
      //     process.env.REACT_APP_ENV !== "develop"
      //       ? [modules.media.location]
      //       : []),
      //     modules.voterMaster.location,
      //     modules.user.location,
      //     modules.election.location,
      //     modules.party.location,
      //     modules.directory.location,
      //     modules.candidate.location,
      //     modules.interestGroup.location,
      //   ],
      // },

    ],
    routes: (
      <Routes>
        <Route path={"/users"} element={<UserIndexPage/>}/>
        <Route path={"/admin"} element={<AdminIndexPage/>}/>
        <Route path={"/users/create"} element={<UserCreateIndexPage/>}/>
        <Route path={"/team"} element={<TeamIndexPage/>}/>
        <Route path={"/dreams"} element={<DreamIndexPage/>}/>
        <Route path={"/notification"} element={<NotificationIndexPage/>}/>
        <Route path={"/ticket"} element={<TicketIndexPage/>}/>
        <Route path={"/chat"} element={<ChatIndexPage/>}/>
      </Routes>
    ),
  };
};