import React from "react";
import { UserModel } from "../../@types/user";
import { RoleModel } from "../../@types/role";

export const MeContext = React.createContext<{
  me: UserModel;
  setMe: (user: UserModel) => void;
  role: RoleModel;
  setRole: (role: RoleModel) => void;
}>({
  me: null,
  role: null,
  setMe: () => {},
  setRole: () => {},
});
