export enum Status {
    Open = "Aberto",
    InProgress = "Em andamento",
    Finished = "Finalizado",
}

export enum Title {
    Suggestion = "Sugestão",
    ReportProblem = "Reportar Problema",
    Others = "Outros"
}