import { DatePicker } from "antd";
import momentGenerateConfig from "rc-picker/lib/generate/moment";

import React from "react";
import type { Moment } from "moment";

const DPM = DatePicker.generatePicker<Moment>(momentGenerateConfig);

export const DatePickerComponent = ({ ...rest }) => {
  return <DPM format={"DD/MM/YYYY"} {...rest} />;
};
