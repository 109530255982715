import React from "react";
import { Col, Row } from "antd";

export const UserCreateIndexPage = () => {
    return (
        <Row>
            <Col span={4}>
                <div style={{ backgroundColor: "red" }}>
                    <h1>User Create Index Page</h1>
                </div>
            </Col>
            <Col span={16}>
                <div style={{ backgroundColor: "red" }}>
                    <h1>User Create Index Page</h1>
                </div>
            </Col>
        </Row>
    );
};
