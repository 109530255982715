import { Card, Col } from "antd";
import Chart from "react-apexcharts";
import React from "react";

interface pieChartProps {
    labels?: string[];
    series: number[];
    type: any;
    title: string;
}

export const PieChart = ({ labels, series, type, title }: pieChartProps) => {
    return (
        <Col span={8}>
            <Card>
                <div id="pie-chart">
                    <Chart
                        options={{
                            labels: labels ,
                            chart: { type:  type  },
                            legend: {
                                position: "bottom"
                            },
                            title: { text:  title  }

                        }
                        }
                        series={series}
                        type={type}
                        height={350}
                    />
                </div>
            </Card>
        </Col>
    );
};