import React, { useEffect, useState } from "react";
import { api } from "../../../services/api";
import { TableDreams } from "../../../containers/tables/table-dream";
import { ApexOptions } from "apexcharts";
import Chart from "react-apexcharts";
import moment from "moment";
import _ from "lodash";
import { DatePickerComponent } from "../../../containers/inputs/date-picker-component/date-picker-component";
import { Button, Card, Col, Row, Statistic } from "antd";
import { PieChart } from "../../../components/pie-chart/pie-chart";


export const DreamIndexPage = () => {
    const [dreams, setDreams] = useState<any>({ dreams: [], totalDreams: 0, dreamsByHour: [] });
    const [dreamPanel, setDreamPanel] = useState<any>({
        metricMonth: [],
        metricLucidity: [],
        metricDreamQuality: [],
        metricSleepQuality: []
    });
    const [date, setDate] = useState({ dateStart: moment().subtract(1, "month"), dateEnd: moment() });
    useEffect(() => {
        api.get("/admin/dreams").then((response) => {
            console.log(response);
            setDreams(response.data);
        })
            .catch((error) => {
                console.log(error);
                return [];
            });
    }, []);
    const fetchPanel = () => {
        api.get("/admin/dreams-panel", {
            params: {
                dateStart: date.dateStart.format("YYYY-MM-DD"),
                dateEnd: date.dateEnd.format("YYYY-MM-DD")
            }
        }).then((response) => {
            console.log(response);
            setDreamPanel(response.data);
        })
            .catch((error) => {
                console.log(error);
                return [];
            });
    };
    useEffect(() => {
        fetchPanel();
    }, []);

    console.log(dreamPanel, "dreams");
    return (
        <div>
            <h1>Sonhos do Dreambook</h1>
            <DatePickerComponent value={date.dateStart} onChange={(val) => setDate({ ...date, dateStart: val })} />
            <DatePickerComponent value={date.dateEnd} onChange={(val) => setDate({ ...date, dateEnd: val })} />
            <Button onClick={fetchPanel}>Search</Button>
            <Row>
                <Card>
                    <Statistic title="Total de sonhos" value={dreams.totalDreams} />
                </Card>
            </Row>
            <div id="line-chart">
                <Row>
                    <Col span={12}>
                        <Card>
                            <Chart
                                options={{
                                    labels: dreamPanel.metricMonth.map((item: any) => moment(item.date).format("DD/MM")),
                                    chart: { type: "line" },
                                    yaxis: {
                                        labels: {
                                            formatter: (val: any) => {
                                                console.log(val, "values");
                                                return val.toFixed(1);
                                            }
                                        }
                                    },
                                    title: { text: `Sonhos ${date.dateStart.format("DD/MM/YYYY")} - ${date.dateEnd.format("DD/MM/YYYY")}` }

                                }
                                }
                                series={[{
                                    type: "column",
                                    name: "Dreams",
                                    data: dreamPanel.metricMonth.map((item: any) => item.count)
                                }, {
                                    type: "line",
                                    name: "Dreams Average",
                                    data: dreamPanel.metricMonth.map((item: any) => _.meanBy(dreamPanel.metricMonth, (item: any) => item.count))
                                }]}
                                type="line"
                                height={350}
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card>
                            <Chart
                                options={{
                                    labels: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
                                    chart: { type: "line" },
                                    yaxis: {
                                        labels: {
                                            formatter: (val: any) => {
                                                console.log(val, "values");
                                                return val.toFixed(1);
                                            }
                                        }
                                    },
                                    title: { text: "Hora do dia com mais registros" }

                                }
                                }
                                series={[{
                                    type: "column",
                                    name: "Dreams",
                                    data: dreams.dreamsByHour.map((item: any) => item.count)
                                }]}
                                type="bar"
                                height={350}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
            <Row>
                <PieChart
                    title={"Grau de lucidez"}
                    type={"pie"}
                    series={dreamPanel.metricLucidity.map((item: any) => item.count)}
                    labels={dreamPanel.metricLucidity.map((item: any) => item.name)}
                />

                <PieChart
                    title={"Qualidade dos sonhos"}
                    type={"pie"}
                    series={dreamPanel.metricDreamQuality.map((item: any) => item.count)}
                    labels={dreamPanel.metricDreamQuality.map((item: any) => item.name)}
                />
                <PieChart
                    title={"Qualidade do sono"}
                    type={"pie"}
                    series={dreamPanel.metricSleepQuality.map((item: any) => item.count)}
                    labels={dreamPanel.metricSleepQuality.map((item: any) => item?.name + "")}
                />
            </Row>

            <TableDreams dataSource={dreams.formatedDream} />
        </div>
    );
};