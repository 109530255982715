import React from "react";
import { Table } from "antd";

export const TableTeams = ({ dataSource }) => {
    return (
        <Table
            dataSource={dataSource}
            columns={[
                {
                    title: "Name",
                    dataIndex: "name",
                    key: "name",
                },
                {
                    title: "Email",
                    dataIndex: "email",
                    key: "email",
                },
                {
                    title: "Role",
                    dataIndex: "role",
                    key: "role",
                }
            ]} />
    );
};